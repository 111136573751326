<template>
  <overlay-scrollbars class="page page-compliance">
    <Compliance />
    <div class="wrapper_confirmation">
      <div class="box box_confirmation">
        <div class="header">
          <div class="title">Confirmation of submitted data</div>
        </div>
        <div class="content">
          <div class="block">
            <div class="block_check">
              <div class="ttl">Confirmation</div>
              <div class="desc">
                <CheckBox
                  :state="confirmationState"
                  :label="$store.getters.contentArr.compliance_confirmation"
                  @changeState="confirmationState = !confirmationState"
                />
              </div>
            </div>
          </div>
          <div class="block block_files">
            <div class="box box_addFiles">
              <div class="ttl">Company registration certificate</div>
              <div class="separ"></div>
              <div v-if="getUploadedFiles('comp_reg_cert').length" class="content content_many">
                <draggable v-model="upload.comp_reg_cert" tag="div" class="draggable">
                <div v-for="(file, key) in getUploadedFiles('comp_reg_cert')" :key="key" class="file">
                  <File class="img" />
                  <div class="btn btn_del" @click="delFile(`comp_reg_cert`, key)"><Close class="img" /></div>
                  <div class="desc">{{ file.name }}</div>
                </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.comp_reg_cert[upload.comp_reg_cert.length - 1].click()"><Close class="img" /></div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.comp_reg_cert[0].click()">Choose Files</div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
                <input
                  v-for="(i, key) in upload.comp_reg_cert"
                  :key="key"
                  type="file"
                  :name="`comp_reg_cert_${key}`"
                  class="hide"
                  ref="comp_reg_cert"
                  @change="addInput($event, 'comp_reg_cert', key)"
                >
            </div>
            <div class="box box_addFiles">
              <div class="ttl">Deed of Incorporation</div>
              <div class="separ"></div>
              <div v-if="getUploadedFiles('deed_incorp').length" class="content content_many">
                <draggable v-model="upload.deed_incorp" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('deed_incorp')" :key="key" class="file">
                    <File class="img" />
                    <div class="btn btn_del" @click="delFile(`deed_incorp`, key)"><Close class="img" /></div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.deed_incorp[upload.deed_incorp.length - 1].click()"><Close class="img" /></div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.deed_incorp[0].click()">Choose Files</div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <input
                v-for="(i, key) in upload.deed_incorp"
                :key="key"
                type="file"
                :name="`deed_incorp_${key}`"
                class="hide"
                ref="deed_incorp"
                @change="addInput($event, 'deed_incorp', key)"
              >
            </div>
            <div class="box box_addFiles">
              <div class="ttl">Articles of Association</div>
              <div class="separ"></div>
              <div v-if="getUploadedFiles('art_assoc').length" class="content content_many">
                <draggable v-model="upload.art_assoc" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('art_assoc')" :key="key" class="file">
                    <File class="img" />
                    <div class="btn btn_del" @click="delFile(`art_assoc`, key)"><Close class="img" /></div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.art_assoc[upload.art_assoc.length - 1].click()"><Close class="img" /></div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.art_assoc[0].click()">Choose Files</div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <input
                v-for="(i, key) in upload.art_assoc"
                :key="key"
                type="file"
                :name="`art_assoc_${key}`"
                class="hide"
                ref="art_assoc"
                @change="addInput($event, 'art_assoc', key)"
              >
            </div>
            <div class="box box_addFiles">
              <div class="ttl">Other documents (Passport of Director and UBO)</div>
              <div class="separ"></div>
              <div v-if="getUploadedFiles('other_doc').length" class="content content_many">
                <draggable v-model="upload.other_doc" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('other_doc')" :key="key" class="file">
                    <File class="img" />
                    <div class="btn btn_del" @click="delFile(`other_doc`, key)"><Close class="img" /></div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.other_doc[upload.other_doc.length - 1].click()"><Close class="img" /></div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.other_doc[0].click()">Choose Files</div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <input
                v-for="(i, key) in upload.other_doc"
                :key="key"
                type="file"
                :name="`other_doc_${key}`"
                class="hide"
                ref="other_doc"
                @change="addInput($event, 'other_doc', key)"
              >
            </div>
            <div class="box box_addFiles">
              <div class="ttl">Agreements of client</div>
              <div class="separ"></div>
              <div v-if="getUploadedFiles('client_agreements').length" class="content content_many">
                <draggable v-model="upload.client_agreements" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('client_agreements')" :key="key" class="file">
                    <File class="img" />
                    <div class="btn btn_del" @click="delFile(`client_agreements`, key)"><Close class="img" /></div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.client_agreements[upload.client_agreements.length - 1].click()"><Close class="img" /></div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.client_agreements[0].click()">Choose Files</div>
                  <div class="desc">(max 5 MB)</div>
                </div>
              </div>
              <input
                v-for="(i, key) in upload.client_agreements"
                :key="key"
                type="file"
                :name="`client_agreements_${key}`"
                class="hide"
                ref="client_agreements"
                @change="addInput($event, 'client_agreements', key)"
              >
            </div>
          </div>
        </div>
        <div class="arrow"><Arrow /></div>
      </div>
      <div class="box box_recom">
        <div class="txt">
          {{ $store.getters.contentArr.compliance_page_minimizing }}
        </div>
        <a :href="'mailto:' + $store.getters.contentArr.suppport_email" class="btn"><Arrow class="img" /></a>
      </div>
      <div class="box box_submit">
        <div class="ttl">Notes from support</div>
        <div class="separ"></div>
        <textarea v-model="notes_from_support" name="" class="content" placeholder="Additionally information list..."></textarea>
        <a @click="submit" href="#" class="btn">Submit data</a>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from '@/components/elem/Info.vue'
import CheckBox from '@/components/elem/CheckBox.vue'
import Compliance from '@/components/elem/Compliance.vue'

import Arrow from '@/components/img/Arrow.vue'
import File from '@/components/img/File.vue'
import Close from '@/components/img/Close.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    Info,
    Compliance,
    CheckBox,
    Arrow,
    File,
    Close,
    draggable,
  },
  data: () => ({
    confirmationState: false,
    upload: {
      comp_reg_cert: [{ name: null }],
      deed_incorp: [{ name: null }],
      art_assoc: [{ name: null }],
      other_doc: [{ name: null }],
      client_agreements: [{ name: null }], 
    },
    notes_from_support: '',
    comp_reg_cert_changed: false,
    deed_incorp_changed: false,
    art_assoc_changed: false,
    other_doc_changed: false,
    client_agreements_changed: false,
  }),
  created () {
    this.$store.dispatch('setUser')
    this.getCertificates()
    this.getIncorporationDeeds()
    this.getAssociationArticles()
    this.getOtherDocuments()
    this.getClientAgreements()
  },
  methods: {
    getClientAgreements () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'compliance/client-agreements')
        .then((response) => {
          let clientAgreementsData = response.data
          clientAgreementsData = clientAgreementsData.sort(function (a, b) {
            return a.pivot.order - b.pivot.order
          })
          if (clientAgreementsData.length > 0) {
            that.upload.client_agreements = clientAgreementsData.map((el) => {
              return {
                id: el.id,
                name: el.name,
                type: 'server',
                order: el.pivot.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAssociationArticles () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'compliance/association-articles')
        .then((response) => {
          let associationArticlesData = response.data
          associationArticlesData = associationArticlesData.sort(function (a, b) {
            return a.pivot.order - b.pivot.order
          })
          if (associationArticlesData.length > 0) {
            that.upload.art_assoc = associationArticlesData.map((el) => {
              return {
                id: el.id,
                name: el.name,
                type: 'server',
                order: el.pivot.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getIncorporationDeeds () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'compliance/incorporation-deeds')
        .then((response) => {
          let incorporationDeedsData = response.data
          incorporationDeedsData = incorporationDeedsData.sort(function (a, b) {
            return a.pivot.order - b.pivot.order
          })
          if (incorporationDeedsData.length > 0) {
            that.upload.deed_incorp = incorporationDeedsData.map((el) => {
              return {
                id: el.id,
                name: el.name,
                type: 'server',
                order: el.pivot.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getOtherDocuments () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'compliance/other-documents')
        .then((response) => {
          let otherDocumentsData = response.data
          otherDocumentsData = otherDocumentsData.sort(function (a, b) {
            return a.pivot.order - b.pivot.order
          })
          if (otherDocumentsData.length > 0) {
            that.upload.other_doc = otherDocumentsData.map((el) => {
              return {
                id: el.id,
                name: el.name,
                type: 'server',
                order: el.pivot.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getCertificates () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'compliance/registration-certificates')
        .then((response) => {
          let registrationCertificatesData = response.data
          registrationCertificatesData = registrationCertificatesData.sort(function (a, b) {
            return a.pivot.order - b.pivot.order
          })
          if (registrationCertificatesData.length > 0) {
            that.upload.comp_reg_cert = registrationCertificatesData.map((el) => {
              return {
                id: el.id,
                name: el.name,
                type: 'server',
                order: el.pivot.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    submit () {
      if (!this.confirmationState) {
        this.$noty.error("Please, confirm that information provided by you")
        return
      }
      let formData = new FormData()

      const uploadKeys = Object.keys(this.upload)
      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.upload[key].length; i++) {
          if (this.upload[key][i].name !== null) {
            let f = {}
            if (this.upload[key][i].type == 'server') {
              f = JSON.stringify(this.upload[key][i])
            } else {
              formData.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }
            formData.append(key + '[' + i + ']', f)
          }
        }
      } 

      formData.append('notes_from_support', this.notes_from_support)
      formData.append('comp_reg_cert_changed', this.comp_reg_cert_changed)
      formData.append('deed_incorp_changed', this.deed_incorp_changed)
      formData.append('art_assoc_changed', this.art_assoc_changed)
      formData.append('other_doc_changed', this.other_doc_changed)
      formData.append('client_agreements_changed', this.client_agreements_changed)

      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'compliance/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            this.$noty.success('You have successfully updated compliance info')
            this.comp_reg_cert_changed = false
            this.deed_incorp_changed = false
            this.art_assoc_changed = false
            this.other_doc_changed = false
            this.client_agreements_changed = false
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      
    },
    getUploadedFiles(el) {
      return this.upload[el].filter(e => e.name !== null)
    },
    addInput(event, name, key) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name
        if (this.getUploadedFiles(name).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')
          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)
        if (this.$refs[name].length < 10) {
          this.checkChangedStatus(name)
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    checkChangedStatus (name) {
      if (name === 'comp_reg_cert') {
        this.comp_reg_cert_changed = true
      }
      if (name === 'deed_incorp') {
        this.deed_incorp_changed = true
      }
      if (name === 'art_assoc') {
        this.art_assoc_changed = true
      }
      if (name === 'other_doc') {
        this.other_doc_changed = true
      }
      if (name === 'client_agreements') {
        this.client_agreements_changed = true
      }
    },
    // getFileName(name, key) {
    //   return this.$refs[name][key].files[0].name
    // },
    delFile(name, key) {
      if (this.upload[name].length > 1) {
        this.upload[name].splice(key, 1)
      } else {
        this.upload[name][0].name = null
      }
      this.checkChangedStatus(name)
    }
  },
  watch: {
    '$store.state.user': function() {
      this.notes_from_support = this.$store.state.user.notes_from_support;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-compliance {
  .box {
    &_confirmation {
      width: 70%;
      position: relative;
      background-color: #000000;
      box-shadow: none;
      z-index: 9;

      .block {
        &_check {
          background: rgba(19, 180, 151, 0.1);
          border-radius: 6px;
          padding: .75rem 1.5rem;

          .ttl {
            padding-bottom: .25rem;
          }

          ::v-deep .checkBox {
            .label {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        &_files {
          margin: .75rem 0;
          display: flex;
          flex-wrap: wrap;

          .box_addFiles {
            width: 48%;
            width: calc(50% - .75rem);
            margin: .75rem 0;
            padding: 1rem 1.75rem;
            background: #22252B;
            box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
            display: flex;
            flex-direction: column;

            &:nth-child(odd) {
              margin-right: .75rem;
            }

            &:nth-child(even) {
              margin-left: .75rem;
            }

            .ttl {
              padding-bottom: .25rem;
            }

            .separ {
              width: 3rem;
              height: .25rem;
              background: linear-gradient(270deg, #56BA9B 0%, #64D1B0 100%), #62CDAC;
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 14px;
              margin: .25rem 0;
            }

            .content {
              min-height: 8rem;
              flex-grow: 1;
              margin-top: 1rem;
              border: 2px solid rgba(19, 180, 151, 0.1);
              box-sizing: border-box;
              filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
              border-radius: 14px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              .choose {
                text-align: center;

                .btn {
                  padding: 1rem 1.75rem;
                  margin: .5rem;
                  background: linear-gradient(270deg, #56BA9B 0%, #64D1B0 100%), #62CDAC;
                  border-radius: 6px;
                  line-height: 1.1;
                }

                .desc {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.3);
                }
              }

              &_many {
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 0.75rem 6px;

                .draggable {
                  display: flex;
                  flex-wrap: wrap;
                  padding: 0;
                }

                .file {
                  position: relative;
                  text-align: center;
                  padding: .75rem .5rem .5rem;
                  width: 6.25rem;
                  max-width: 6.25rem;

                  svg.img {
                    width: 3rem;
                    height: 3rem;
                    margin: 0 auto;
                  }

                  .btn {
                    &_del {
                      position: absolute;
                      top: 0;
                      right: 1rem;
                      width: 1.25rem;
                      height: 1.25rem;
                      border-radius: 50%;
                      background-color: #B03636;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      svg.img {
                        width: 40%;
                        height: 40%;
                      }
                    }
                  }

                  .desc {
                    font-size: 10px;
                    color: rgba(255, 255, 255, 0.3);
                    word-break: break-word;
                  }
                }

                .add {
                  text-align: center;
                  padding: 0 .5rem .5rem;
                  width: 6rem;
                  max-width: 6rem;

                  .btn {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin: .75rem auto .25rem;
                    border-radius: 50%;
                    background: linear-gradient(270deg, #56BA9B 0%, #64D1B0 100%), #62CDAC;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg.img {
                      width: 45%;
                      height: 45%;
                      transform: rotate(45deg);
                    }
                  }

                  .desc {
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.3);
                  }
                }
              }
            }
          }
        }
      }

      .arrow {
        position: absolute;
        bottom: calc(calc(100% - 10rem) / 2);
        right: 0;
        transform: translate(50%, 50%);
        width: 3.5rem;
        height: 3.5rem;
        background-color: #22252B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        ::v-deep svg {
          width: 55%;
          height: 55%;

          path {
            fill: #ffffff;
          }
        }
      }
    }

    &_recom {
      position: absolute;
      top: 0;
      right: 0;
      width: 27%;
      height: 9rem;
      padding: .75rem 1.5rem;
      margin: 0;
      background-image: url('/img/group_38.png');
      background-position: 107% -25%;
      background-repeat: no-repeat;
      background-size: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1440px) {
        height: 13rem;
      }

      .txt {
        width: 75%;
        font-size: 14px;
        font-weight: 300;
        padding-right: 1rem;
      }

      .btn {
        width: 2.5rem;
        height: 2.5rem;
        background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
        border-radius: 6px;
        margin: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        ::v-deep svg.img {
          width: 60%;
          height: 60%;

          path {
            fill: #ffffff;
          }
        }
      }
    }

    &_submit {
      position: absolute;
      top: 12rem;
      bottom: 0;
      right: 0;
      width: 35%;
      width: calc(30% + 5rem);
      margin: 0;
      padding: 1.25rem 2rem 0.75rem 8rem;
      z-index: 0;
      display: flex;
      flex-direction: column;

      @media (max-width: 1440px) {
        top: 15rem;
      }

      .ttl {
        padding-bottom: .25rem;
      }

      .separ {
        width: 3rem;
        height: .25rem;
        background: linear-gradient(270deg, #56BA9B 0%, #64D1B0 100%), #62CDAC;
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 14px;
        margin: .25rem 0;
      }

      .content {
        width: 100%;
        flex-grow: 1;
        background: #22252B;
        border: 1px solid #2F363D;
        box-sizing: border-box;
        border-radius: 6px;
        resize: none;
        margin: 1rem 0;
        padding: .75rem 1rem;
        color: #ffffff;
      }

      .btn {
        width: 95%;
        width: calc(100% - 1rem);
        height: 4.5rem;
        margin: .5rem;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
        box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
        border-radius: 6px;
      }
    }
  }

  .wrapper_confirmation {
    position: relative;
    width: 100%;
  }
}
</style>